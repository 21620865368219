<template>
  <div class='py-12'>
    <welcome-title title='Contact Us' />
      <div class=''>
         <div class='mb-16'>
            <h2 class='mb-2 text-xl font-semibold text-gray-900'>Local Congress Secretariat of APSR 2022</h2>
            <div class='px-8 py-6 rounded-lg' style='background-color: var(--lightestColor);'>
               <div>
                  <div class='flex flex-row justify-start items-start gap-x-2 mb-4'>
                    <label class='font-semibold w-20 inline-block'>Address</label>
                    513, Yeongdong-daero, Gangnam-gu, Seoul 06164, Korea
                  </div>
                  <div class='flex flex-row justify-start items-start gap-x-2 mb-4'>
                    <label class='font-semibold w-20 inline-block'>Fax</label>
                    82-2-6944-8306
                  </div>
                  <div class='flex flex-row justify-start items-start gap-x-2 mb-4'>
                    <label class='font-semibold w-20 inline-block'>E-mail</label>
                    <div>
                      <div class=''>Abstract submissions: <a class="underline" :style='themeTextColorStyle' href="mailto:abstract@apsr2022.org" target="_blank">abstract@apsr2022.org</a></div>
                      <div class=''>Registration: <a class="underline" :style='themeTextColorStyle' href="mailto:reg@apsr2022.org" target="_blank">reg@apsr2022.org</a></div>
                      <div class=''>Accommodation: <a class="underline" :style='themeTextColorStyle' href="mailto:hotel@apsr2022.org" target="_blank">hotel@apsr2022.org</a></div>
                      <div class=''>Tour: <a class="underline" :style='themeTextColorStyle' href="mailto:tour@apsr2022.org" target="_blank">tour@apsr2022.org</a></div>
                      <div class=''>Sponsor and Exhibition: <a class="underline" :style='themeTextColorStyle' href="mailto:apsr@apsr2022.org" target="_blank">apsr@apsr2022.org</a></div>
                      <div class=''>General information: <a class="underline" :style='themeTextColorStyle' href="mailto:info@apsr2022.org" target="_blank">info@apsr2022.org</a></div>
                      <div class=''>Media: <a class="underline" :style='themeTextColorStyle' href="mailto:media@apsr2022.org" target="_blank">media@apsr2022.org</a></div>
                    </div>
                  </div>
               </div>
              </div>
         </div>
         <div class='mb-16'>
            <h2 class='mb-2 text-xl font-semibold text-gray-900'>Secretariat (General Enquires)</h2>
            <div class='px-8 py-6 rounded-lg' style='background-color: var(--lightestColor)'>
               <div>
                  <div class='flex flex-row justify-start items-start gap-x-2 mb-4'>
                    <label class='font-semibold inline-block'>Asian Pacific Society of Respirology</label>
                  </div>
                  <div class='flex flex-row justify-start items-center gap-x-2 mb-4'>
                    <label class='font-semibold w-20 inline-block'>Address</label>
                    2F, UK's Bldg. 2-29-3 Hongo, Bunkyo-ku,Tokyo 113-0033 Japan
                  </div>
                  <div class='flex flex-row justify-start items-center gap-x-2 mb-4'><label class='font-semibold w-20 inline-block'>Fax</label>FAX +81-3-5684-3382</div>
                  <div class='flex flex-row justify-start items-center gap-x-2 mb-4'><label class='font-semibold w-20 inline-block'>E-mail</label>
                  <a class="underline" :style='themeTextColorStyle' href="mailto:APSRinfo@theapsr.org" target="_blank">APSRinfo@theapsr.org</a>
                  </div>
               </div>
            </div>
         </div>
         <div class='mb-16'>
            <h2 class='mb-2 text-xl font-semibold text-gray-900'>Further information on Asian Pacific Society of Respirology</h2>
            <div class='px-8 py-6 rounded-lg' style='background-color: var(--lightestColor)'>
               <div>
                <a class="underline" :style='themeTextColorStyle' href="http://www.apsresp.org/index.html" target="_blank">http://www.apsresp.org/index.html</a>
               </div>
            </div>
         </div>
      </div>
      
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WelcomeTitle from '@/components/apsr2022/WelcomeTitle.vue'

export default {
  name: 'ContactUs',
  components: {
    WelcomeTitle,
  },
  computed: {
    ...mapGetters('events', [
      'themeTextColorStyle',
    ]),
  },
}
</script>
